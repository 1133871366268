/** BEGIN: Non Openmrs CSS **/
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
* {
	font-family: 'Zilla Slab', serif;
}
div.container {
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	margin-top: 30px;
	border: 1px solid whitesmoke;
	padding: 21px;
	border-radius: 4px;
}
h4.title {
	text-align: center;
	margin-bottom: 45px;
}
:root {
  --omrs-color-ink-lowest-contrast: #175e6e;
	--omrs-color-ink-low-contrast: #175e6e;
	--omrs-color-ink-medium-contrast: #175e6e;
	--omrs-color-interaction: #1f788c;
	--omrs-color-interaction-minus-two: white;
	--omrs-color-danger: #b50706;
	--omrs-color-bg-low-contrast: #eff1f2;
	--omrs-color-ink-high-contrast: #200b00;
	--omrs-color-bg-high-contrast: #ffffff;
	
}
/** END: Non Openmrs CSS **/
div.omrsInputGroup {
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
}

/* Input*/
.omrsInputUnderlined > input,
.omrsInputFilled > input {
	border: none;
	/*border-bottom: 2px solid var(--omrs-color-ink-medium-contrast);*/
	border-bottom: 2px solid #e1e1e1;
	width: 100%;
	height: 2rem;
	font-size: 1.0625rem;
	font-weight: 500;
	line-height: 147.6%;
	padding-bottom: 0;
	font-family: Roboto, sans-serif;
	font-size: 14px;
}

.omrsInputUnderlined > input:focus,
.omrsInputFilled > input:focus {
	outline: none;
}

.omrsInputUnderlined > .omrsInputLabel,
.omrsInputFilled > .omrsInputLabel {
	line-height: 147.6%;
	color: #175e6e;
	font-weight: 700;
	transition: top .2s;
}


.omrsInputUnderlined > .omrsInputHelper,
.omrsInputFilled > .omrsInputHelper {
	font-size: 0.9375rem;
	color: var(--omrs-color-ink-medium-contrast);
	letter-spacing: 0.0275rem;
	margin: 0.125rem 0.875rem;
}

.omrsInputUnderlined > input:hover,
.omrsInputFilled > input:hover {
	background: var(--omrs-color-interaction-minus-two);
	border-color: var(--omrs-color-ink-lowest-contrast);
	transition: .5s;
}

.omrsInputUnderlined > input:focus + .omrsInputLabel,
.omrsInputUnderlined > input:valid + .omrsInputLabel,
.omrsInputFilled > input:focus + .omrsInputLabel,
.omrsInputFilled > input:valid + .omrsInputLabel {
	top: 0;
	font-size: 0.9375rem;
	margin-bottom: 32px;;
}

.omrsInputUnderlined:not(.omrsInputDanger) > input:focus + .omrsInputLabel,
.omrsInputFilled:not(.omrsInputDanger) > input:focus + .omrsInputLabel {
	color: var(--omrs-color-interaction);
}

.omrsInputUnderlined:not(.omrsInputDanger) > input:focus,
.omrsInputFilled:not(.omrsInputDanger) > input:focus {
	border-color: var(--omrs-color-interaction);
}

.omrsInputUnderlined:not(.omrsInputDanger) > input:focus ~ svg,
.omrsInputFilled:not(.omrsInputDanger) > input:focus ~ svg {
	fill: var(--omrs-color-ink-high-contrast);
}

/** DISABLED **/

.omrsInputUnderlined > input:disabled {
	background: var(--omrs-color-bg-low-contrast);
	cursor: not-allowed;
}

.omrsInputUnderlined > input:disabled + .omrsInputLabel,
.omrsInputUnderlined > input:disabled ~ .omrsInputHelper{
	color: var(--omrs-color-ink-low-contrast);
}

.omrsInputUnderlined > input:disabled ~ svg {
	fill: var(--omrs-color-ink-low-contrast);
}


/** DANGER **/

.omrsInputUnderlined.omrsInputDanger > .omrsInputLabel, .omrsInputUnderlined.omrsInputDanger > .omrsInputHelper,
.omrsInputFilled.omrsInputDanger > .omrsInputLabel, .omrsInputFilled.omrsInputDanger > .omrsInputHelper{
	color: var(--omrs-color-danger);
}

.omrsInputDanger > svg {
	fill: var(--omrs-color-danger);
}

.omrsInputDanger > input {
	border-color: var(--omrs-color-danger);
}

.omrsInputUnderlined > input {
	background: var(--omrs-color-bg-high-contrast);
}
.omrsInputFilled > input {
	background: var(--omrs-color-bg-low-contrast);
}

@media(min-width: 1024px) {
	div.omrsInputGroup {
		width: 18em;
	}
}
