@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
section {
    margin: 25px;
}

h3 {
    color: #175e6e;
    text-align: left;
}

.hr {
    border: 1px solid #999999;
    width: 40%;
    margin: 0px;
}

.sectionTitle {
    text-align: left;
    color: #175e6e;
    font-size: 1em;
    font-weight: 700;
}

.sectionSubtitle {
    text-align: left;
    color: #000;
    font-size: 1em;
    font-weight: 700;
}

.dangerText {
    color: crimson;
    font-size: 0.75em;
    text-align: left;
}
.buttonSelected,
.submitButton {
    border: 1px solid #ffb103;
    color: black;
    background: #ffb103; /*#ff5900;*/
    font-weight: 700;
    padding: 10px 20px;
    width: fit-content;
    font-size: 1.05em;
    white-space: nowrap;
    transition: all ease 0.3s;
    cursor: pointer;
    transition: all ease 0.3s;
    border-radius: 25px;
}
.button_standar {
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    width: fit-content;
    font-size: 1.05em;
}
.button_submit {
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    font-size: 1.05em;
}
.submitButton{
    font-size: 1rem !important;
}
.submitButton:disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: none;
}

.buttonIdle {
    border: 1px solid #175e6e;
    color: #175e6e;
    background: white;
    font-weight: 700;
    padding: 10px;
    width: fit-content;
    font-size: 1.05em;
    white-space: nowrap;
    transition: all ease 0.3s;
    cursor: pointer;
    border-radius: 25px;
}
.buttonIdle::placeholder{
    color: #175e6e;
}
.buttonSelected::placeholder{
    color: #000;
}

.donationTypeContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.formContainer {
    text-align: left;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
}

.flexContainer div {
    color: #404040;
}

.flexContainer .buttonSelected, .flexContainer .buttonIdle {
    width: 120px;
    box-sizing: border-box;
}

.inputIcon {
    position: absolute;
    color: #e1e1e1;
    right: 0rem;
    bottom: 1em;
    /*left: -0.5em;*/
}

.text {
    text-align: left;
    color: #000000;
    line-height: 1.5em;
    font-weight: 500;
    font-family: Roboto, sans-serif !important;
}

a {
    color: #999999;
}

.paymentMethodIcon {
    color: #999999;
    margin: 2px;
}

.orderSummary {
    display: none;
}

.expiration {
    width: 10%;
}
.container_icon{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
/*.icon {
    position: relative;
    top: 10px;
    left: 0;
}*/

.conditionsNotMet {
    font-family: "Roboto", sans-serif;
    background-color: #fef3c7;
    padding: 15px 25px;
    border-radius: 5px;
    color: #78350f;
    margin-top: 1em;
    transition: all ease 0.3s;
}

/*
    |===================|
    |                   |
    |   1024 px and up  |
    |                   |
    |===================|
*/

@media screen and (min-width: 1024px) {
    section {
        width: 70%;
        margin-left: 150px;
        margin-bottom: 60px;
    }
    
    .sectionTitle {
        font-size: 1.25em;
    }

    .buttonSelected, .buttonIdle {
        width: 250px;
        height: 50px;
        font-size: 1.05em;
    }

    .flexContainer {
        width: 50%;
    }

    .formContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px 40px;
        width: 70%;
    }

    .expirationContainer {
        display: flex;
        flex-direction: row;
        gap: 20px 40px;
    }

    .mainContainer div {
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .mainContainer span {
        float: right;
    }

    .text {
        width: 50%;
    }

    .conditionsNotMet {
        width: 50%;
    }
}

@media(min-width: 1279px) {
    .orderSummary {
        display: block;
        width: 375px;
        border-radius: 10px;
        border:1px solid #E1E1E1;
        position: fixed;
        right: 150px;
        top: 150px;
        box-shadow: 0px 5px 0px 0px #175e6e;
    }

    .orderSummary .mainContainer {
        display: flex;
        flex-direction: column;
        margin: 25px;
        text-align: left;
    }

    .orderSummary h1 {
        font-size: 1.25em;
        font-weight: 700;
    }

    .orderSummary hr {
        margin: 10px 0px;
    }

}

/* @media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
   .flexContainer {
       width: 50%;
   }

   .expirationContainer {
       width: 50%;
   }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .flexContainer {
        width: 50%;
    }

    .expirationContainer {
        width: 50%;
    }
} */

/*
    |===================|
    |                   |
    |   iPad landscape  |
    |                   |
    |===================|
*/
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .orderSummary {
        display: none;
    }

    .flexContainer {
        flex-direction: row;
        width: 100%;
    }
}

