.main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    font-family: Roboto, sans-serif;
}
.page__indicator{
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #175e6e;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}
.page__indicator:hover{
    background: #e1e1e1;
}
.page__indicator_disabled{
    cursor: default;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #E1E1E1;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}