.main {
    position: relative;
}
.content {
    background: white;
    display: flex;
    min-height: calc(100vh - 358px);
    padding: 100px 35px;
    flex-direction: column;
}
.content h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.content h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.content p, .content ul li, .content ol li, .content p a {
    font-size: 12pt;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: left;
    font-family: Roboto, sans-serif;
}
a{
    color: #000000;
}
/*
.main {
    min-height: 100vh;
    text-align: left;
    color: #333;
}

.main p,
.main h3 {
    text-align: left;
    line-height: 2;
    color: #333;
}

.main ol li,
.main ul li {
    color: #333;
    line-height: 2;
}*/
