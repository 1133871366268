.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.contanier{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.contanier input{
    font-weight: 600;
    text-transform: uppercase;
}
.content_buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
input::file-selector-button{
    background: #175e6e;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-family: "Zilla Slab";
    font-weight: 600;
}
.button_standar {
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
}
.button_standar[disabled] {
    background-color: #d9d9d9;
    color: #a6a6a6;
    cursor: not-allowed;
}
.button_submit {
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
}
@media (max-width: 768px) {
    .contanier{
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 10px;
        flex-direction: column;
    }
}