@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
footer{
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    padding: 45px 35px 45px 35px;
    background: #175e6e;
    gap: 20px;
    z-index: 10;
}
footer p, footer a {
    color: white;
    text-align: left;
    line-height: 1.2;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    padding: 0;
    margin: 5px 0;
}
.textContainer{
    width: 50%;
}
.logo {
    width: 350px;
    height: auto;
}

@media screen and (max-width: 720px) {
    footer {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    footer p{
        text-align: center;
    }
    .textContainer {
        width: 100%;
    }
    .logo {
        width: 250px;
    }
}