@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
.PhoneInput{
    width: 100% !important;
    align-items: end !important;
}
.PhoneInputCountry{
    align-items: end !important;
    bottom: 12px !important;
}
.PhoneInputCountryIcon{
    background: none;
    border: none;
    border-radius: 3px;
}
.PhoneInputCountrySelect{
    appearance: none;
}
.PhoneInputInput{
    border: none;
    /*border-bottom: 2px solid var(--omrs-color-ink-medium-contrast);*/
    border-bottom: 2px solid #e1e1e1 !important;
    width: 100%;
    height: 2rem;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 147.6%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}
.PhoneInputInput:focus{
    outline: none;
    border-bottom: 2px solid #175E6EFF !important;
    trasition: 0.5s;
}