@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
.cmain {
    width: 300px;
    height: 175px;
    background: rgb(23,94,110);
    background: linear-gradient(152deg, rgba(23,94,110,1) 0%, rgba(31,120,140,1) 100%);
    border-radius: 10px;
    position: relative;
}

.cimain {
    width: 300px;
    height: 175px;
    background-color: #e1e1e1;
    border-radius: 10px;
    position: relative;
}

.logo,
.chip,
.number {
    position: absolute;
    color: white;
}

.logo {
    right: 20px;
    top: 20px;
}

.chip {
    left: 20px;
    top: 75px;
}

.number {
    left: 20px;
    bottom: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.name {
    color: white;
    position: absolute;
    left: 20px;
    bottom: 40px;
    font-weight: 700;
    font-size: 20px;
}
.actions_card{
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: flex;
    flex-direction: row;

    gap: 5px;
}
.activate,.delete{
    font-size: 0.8em;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    background: #b9b9b9;
    transition: .5s;
}
.activate:hover{
    background: #1f788c;
}
.delete:hover{
    background: #f43f5e;
}