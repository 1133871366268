@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
.main {
    position: relative;
}
.content {
    background: white;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 358px);
    padding: 100px 0;
    align-items: center;
}
.main_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.login_container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin-bottom: 50px;
}
.login_container img{
    width: 250px;
    height: auto;
}
.main_container > form{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 650px;
    justify-content: center;
    padding: 45px 25px;
    gap: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px 0 0 10px;
    box-sizing: border-box;
}
.form_group{
    display: flex;
    flex-direction: column;
    position: relative;
}
.container_recover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 40%;
}
.form_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form_group .label_form{
    font-weight: 700;
    font-size: 1.05rem;
    margin: 0;
    color: #175e6e;
    text-transform: capitalize;
}
.form_group input{
    padding: 0.4rem;
    border:none;
    border-bottom: 2px solid #e1e1e1;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.05rem;
    outline: none;
}
.form_group input:hover, .form_group input:focus{
    border-bottom: 2px solid #175e6e;
}
.password_function{
    color: #e1e1e1;
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    right: 5px;
}
.form_group span, .form_row span{
    font-weight: 600;
    font-size: 0.95rem;
    display: inline-block;
}
.main_container h1,p{
    text-align: center;
}
h1{
    font-weight: 700;
    font-size: 2.5rem;
    margin: 0;
}
p{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.05rem;
    margin: 0;
}
.button_standar {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    font-size: 1.05em;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
    width: fit-content;
}
.button_standar:disabled{
    background-color: #d9d9d9;
    color: #a1a1a1;
}
.button_register {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #175e6e;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    font-size: 1.05em;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
    width: fit-content;
}
.button_register:disabled{
    background-color: #d9d9d9;
    color: #a1a1a1;
}
.button_submit {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    font-size: 1.05em;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
}

.banner_login img{
    height: 650px;
    width: 400px;
    object-fit: cover;
    border-radius: 0 10px 10px 0;
    margin-top: 4px;
}
@media screen and (max-width: 900px){
    .container_recover{
        width: 80%;
    }
    .main_container{
        flex-direction: column;
    }
    .banner_login{
        display: none;
    }
    .main_container > form{
        border-radius: 0 0 10px 10px;
        border: none;
        width: 70%;
        height: 100%;
    }
    .login_container{
        margin-bottom: 0px;
    }
}
.text {
    text-align: left;
    color: #000000;
    line-height: 1.5em;
    font-weight: 500;
    font-family: Roboto, sans-serif !important;
}
@media screen and (max-width: 575px){
    .container_recover{
        width: 100%;
    }
    .main_container{
        flex-direction: column;
    }
    .banner_login{
        display: none;
    }
    .main_container > form{
        border-radius: 0 0 10px 10px;
        border: none;
        width: 100%;
        height: 100%;
    }
    .login_container{
        margin-bottom: 0px;
    }
}