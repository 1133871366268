@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PhoneInputInput {
  border: none !important;
  border-bottom: 1px solid #bbb !important;
}

.PhoneInput {
  width: 85% !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: rgba(255, 89, 0, 1) !important;
}

* {
	font-family: 'Zilla Slab', serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
    margin: 25px;
}

h3 {
    color: #175e6e;
    text-align: left;
}

.Donacion_hr__ery48 {
    border: 1px solid #999999;
    width: 40%;
    margin: 0px;
}

.Donacion_sectionTitle__3nB-w {
    text-align: left;
    color: #175e6e;
    font-size: 1em;
    font-weight: 700;
}

.Donacion_sectionSubtitle__sfn_N {
    text-align: left;
    color: #000;
    font-size: 1em;
    font-weight: 700;
}

.Donacion_dangerText__36EAR {
    color: crimson;
    font-size: 0.75em;
    text-align: left;
}
.Donacion_buttonSelected__XyQd6,
.Donacion_submitButton__pADuy {
    border: 1px solid #ffb103;
    color: black;
    background: #ffb103; /*#ff5900;*/
    font-weight: 700;
    padding: 10px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.05em;
    white-space: nowrap;
    transition: all ease 0.3s;
    cursor: pointer;
    transition: all ease 0.3s;
    border-radius: 25px;
}
.Donacion_button_standar__196zZ {
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.05em;
}
.Donacion_button_submit__28DP0 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    font-size: 1.05em;
}
.Donacion_submitButton__pADuy{
    font-size: 1rem !important;
}
.Donacion_submitButton__pADuy:disabled {
    cursor: not-allowed;
    background-color: #eee;
    border: none;
}

.Donacion_buttonIdle__2osN5 {
    border: 1px solid #175e6e;
    color: #175e6e;
    background: white;
    font-weight: 700;
    padding: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.05em;
    white-space: nowrap;
    transition: all ease 0.3s;
    cursor: pointer;
    border-radius: 25px;
}
.Donacion_buttonIdle__2osN5:-ms-input-placeholder{
    color: #175e6e;
}
.Donacion_buttonIdle__2osN5::placeholder{
    color: #175e6e;
}
.Donacion_buttonSelected__XyQd6:-ms-input-placeholder{
    color: #000;
}
.Donacion_buttonSelected__XyQd6::placeholder{
    color: #000;
}

.Donacion_donationTypeContainer__1mEpN {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
}

.Donacion_formContainer__3ecXq {
    text-align: left;
}

.Donacion_flexContainer__1FR4- {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
}

.Donacion_flexContainer__1FR4- div {
    color: #404040;
}

.Donacion_flexContainer__1FR4- .Donacion_buttonSelected__XyQd6, .Donacion_flexContainer__1FR4- .Donacion_buttonIdle__2osN5 {
    width: 120px;
    box-sizing: border-box;
}

.Donacion_inputIcon__20Nbi {
    position: absolute;
    color: #e1e1e1;
    right: 0rem;
    bottom: 1em;
    /*left: -0.5em;*/
}

.Donacion_text__1UOF4 {
    text-align: left;
    color: #000000;
    line-height: 1.5em;
    font-weight: 500;
    font-family: Roboto, sans-serif !important;
}

a {
    color: #999999;
}

.Donacion_paymentMethodIcon__uwft9 {
    color: #999999;
    margin: 2px;
}

.Donacion_orderSummary__28CJv {
    display: none;
}

.Donacion_expiration__50Zqg {
    width: 10%;
}
.Donacion_container_icon__2c1XM{
    display: flex;
    justify-content: start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
/*.icon {
    position: relative;
    top: 10px;
    left: 0;
}*/

.Donacion_conditionsNotMet__1g8hJ {
    font-family: "Roboto", sans-serif;
    background-color: #fef3c7;
    padding: 15px 25px;
    border-radius: 5px;
    color: #78350f;
    margin-top: 1em;
    transition: all ease 0.3s;
}

/*
    |===================|
    |                   |
    |   1024 px and up  |
    |                   |
    |===================|
*/

@media screen and (min-width: 1024px) {
    section {
        width: 70%;
        margin-left: 150px;
        margin-bottom: 60px;
    }
    
    .Donacion_sectionTitle__3nB-w {
        font-size: 1.25em;
    }

    .Donacion_buttonSelected__XyQd6, .Donacion_buttonIdle__2osN5 {
        width: 250px;
        height: 50px;
        font-size: 1.05em;
    }

    .Donacion_flexContainer__1FR4- {
        width: 50%;
    }

    .Donacion_formContainer__3ecXq {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 20px 40px;
        gap: 20px 40px;
        width: 70%;
    }

    .Donacion_expirationContainer__2ykdi {
        display: flex;
        flex-direction: row;
        grid-gap: 20px 40px;
        gap: 20px 40px;
    }

    .Donacion_mainContainer__1ozHx div {
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .Donacion_mainContainer__1ozHx span {
        float: right;
    }

    .Donacion_text__1UOF4 {
        width: 50%;
    }

    .Donacion_conditionsNotMet__1g8hJ {
        width: 50%;
    }
}

@media(min-width: 1279px) {
    .Donacion_orderSummary__28CJv {
        display: block;
        width: 375px;
        border-radius: 10px;
        border:1px solid #E1E1E1;
        position: fixed;
        right: 150px;
        top: 150px;
        box-shadow: 0px 5px 0px 0px #175e6e;
    }

    .Donacion_orderSummary__28CJv .Donacion_mainContainer__1ozHx {
        display: flex;
        flex-direction: column;
        margin: 25px;
        text-align: left;
    }

    .Donacion_orderSummary__28CJv h1 {
        font-size: 1.25em;
        font-weight: 700;
    }

    .Donacion_orderSummary__28CJv hr {
        margin: 10px 0px;
    }

}

/* @media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
   .flexContainer {
       width: 50%;
   }

   .expirationContainer {
       width: 50%;
   }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .flexContainer {
        width: 50%;
    }

    .expirationContainer {
        width: 50%;
    }
} */

/*
    |===================|
    |                   |
    |   iPad landscape  |
    |                   |
    |===================|
*/
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .Donacion_orderSummary__28CJv {
        display: none;
    }

    .Donacion_flexContainer__1FR4- {
        flex-direction: row;
        width: 100%;
    }
}


/** BEGIN: Non Openmrs CSS **/
* {
	font-family: 'Zilla Slab', serif;
}
div.Input_container__3K1fy {
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	margin-top: 30px;
	border: 1px solid whitesmoke;
	padding: 21px;
	border-radius: 4px;
}
h4.Input_title__wtr9g {
	text-align: center;
	margin-bottom: 45px;
}
:root {
  --omrs-color-ink-lowest-contrast: #175e6e;
	--omrs-color-ink-low-contrast: #175e6e;
	--omrs-color-ink-medium-contrast: #175e6e;
	--omrs-color-interaction: #1f788c;
	--omrs-color-interaction-minus-two: white;
	--omrs-color-danger: #b50706;
	--omrs-color-bg-low-contrast: #eff1f2;
	--omrs-color-ink-high-contrast: #200b00;
	--omrs-color-bg-high-contrast: #ffffff;
	
}
/** END: Non Openmrs CSS **/
div.Input_omrsInputGroup__1gxHo {
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
}

/* Input*/
.Input_omrsInputUnderlined__VNOGh > input,
.Input_omrsInputFilled__bj2Na > input {
	border: none;
	/*border-bottom: 2px solid var(--omrs-color-ink-medium-contrast);*/
	border-bottom: 2px solid #e1e1e1;
	width: 100%;
	height: 2rem;
	font-size: 1.0625rem;
	font-weight: 500;
	line-height: 147.6%;
	padding-bottom: 0;
	font-family: Roboto, sans-serif;
	font-size: 14px;
}

.Input_omrsInputUnderlined__VNOGh > input:focus,
.Input_omrsInputFilled__bj2Na > input:focus {
	outline: none;
}

.Input_omrsInputUnderlined__VNOGh > .Input_omrsInputLabel__3XjD1,
.Input_omrsInputFilled__bj2Na > .Input_omrsInputLabel__3XjD1 {
	line-height: 147.6%;
	color: #175e6e;
	font-weight: 700;
	transition: top .2s;
}


.Input_omrsInputUnderlined__VNOGh > .Input_omrsInputHelper__ZnzyH,
.Input_omrsInputFilled__bj2Na > .Input_omrsInputHelper__ZnzyH {
	font-size: 0.9375rem;
	color: #175e6e;
	color: var(--omrs-color-ink-medium-contrast);
	letter-spacing: 0.0275rem;
	margin: 0.125rem 0.875rem;
}

.Input_omrsInputUnderlined__VNOGh > input:hover,
.Input_omrsInputFilled__bj2Na > input:hover {
	background: white;
	background: var(--omrs-color-interaction-minus-two);
	border-color: #175e6e;
	border-color: var(--omrs-color-ink-lowest-contrast);
	transition: .5s;
}

.Input_omrsInputUnderlined__VNOGh > input:focus + .Input_omrsInputLabel__3XjD1,
.Input_omrsInputUnderlined__VNOGh > input:valid + .Input_omrsInputLabel__3XjD1,
.Input_omrsInputFilled__bj2Na > input:focus + .Input_omrsInputLabel__3XjD1,
.Input_omrsInputFilled__bj2Na > input:valid + .Input_omrsInputLabel__3XjD1 {
	top: 0;
	font-size: 0.9375rem;
	margin-bottom: 32px;;
}

.Input_omrsInputUnderlined__VNOGh:not(.Input_omrsInputDanger__1ivsU) > input:focus + .Input_omrsInputLabel__3XjD1,
.Input_omrsInputFilled__bj2Na:not(.Input_omrsInputDanger__1ivsU) > input:focus + .Input_omrsInputLabel__3XjD1 {
	color: #1f788c;
	color: var(--omrs-color-interaction);
}

.Input_omrsInputUnderlined__VNOGh:not(.Input_omrsInputDanger__1ivsU) > input:focus,
.Input_omrsInputFilled__bj2Na:not(.Input_omrsInputDanger__1ivsU) > input:focus {
	border-color: #1f788c;
	border-color: var(--omrs-color-interaction);
}

.Input_omrsInputUnderlined__VNOGh:not(.Input_omrsInputDanger__1ivsU) > input:focus ~ svg,
.Input_omrsInputFilled__bj2Na:not(.Input_omrsInputDanger__1ivsU) > input:focus ~ svg {
	fill: #200b00;
	fill: var(--omrs-color-ink-high-contrast);
}

/** DISABLED **/

.Input_omrsInputUnderlined__VNOGh > input:disabled {
	background: #eff1f2;
	background: var(--omrs-color-bg-low-contrast);
	cursor: not-allowed;
}

.Input_omrsInputUnderlined__VNOGh > input:disabled + .Input_omrsInputLabel__3XjD1,
.Input_omrsInputUnderlined__VNOGh > input:disabled ~ .Input_omrsInputHelper__ZnzyH{
	color: #175e6e;
	color: var(--omrs-color-ink-low-contrast);
}

.Input_omrsInputUnderlined__VNOGh > input:disabled ~ svg {
	fill: #175e6e;
	fill: var(--omrs-color-ink-low-contrast);
}


/** DANGER **/

.Input_omrsInputUnderlined__VNOGh.Input_omrsInputDanger__1ivsU > .Input_omrsInputLabel__3XjD1, .Input_omrsInputUnderlined__VNOGh.Input_omrsInputDanger__1ivsU > .Input_omrsInputHelper__ZnzyH,
.Input_omrsInputFilled__bj2Na.Input_omrsInputDanger__1ivsU > .Input_omrsInputLabel__3XjD1, .Input_omrsInputFilled__bj2Na.Input_omrsInputDanger__1ivsU > .Input_omrsInputHelper__ZnzyH{
	color: #b50706;
	color: var(--omrs-color-danger);
}

.Input_omrsInputDanger__1ivsU > svg {
	fill: #b50706;
	fill: var(--omrs-color-danger);
}

.Input_omrsInputDanger__1ivsU > input {
	border-color: #b50706;
	border-color: var(--omrs-color-danger);
}

.Input_omrsInputUnderlined__VNOGh > input {
	background: #ffffff;
	background: var(--omrs-color-bg-high-contrast);
}
.Input_omrsInputFilled__bj2Na > input {
	background: #eff1f2;
	background: var(--omrs-color-bg-low-contrast);
}

@media(min-width: 1024px) {
	div.Input_omrsInputGroup__1gxHo {
		width: 18em;
	}
}

.PhoneInput{
    width: 100% !important;
    align-items: end !important;
}
.PhoneInputCountry{
    align-items: end !important;
    bottom: 12px !important;
}
.PhoneInputCountryIcon{
    background: none;
    border: none;
    border-radius: 3px;
}
.PhoneInputCountrySelect{
    -webkit-appearance: none;
            appearance: none;
}
.PhoneInputInput{
    border: none;
    /*border-bottom: 2px solid var(--omrs-color-ink-medium-contrast);*/
    border-bottom: 2px solid #e1e1e1 !important;
    width: 100%;
    height: 2rem;
    font-size: 1.0625rem;
    font-weight: 500;
    line-height: 147.6%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}
.PhoneInputInput:focus{
    outline: none;
    border-bottom: 2px solid #175E6EFF !important;
    trasition: 0.5s;
}
body::-webkit-scrollbar{
    width: .5em;
}
body::-webkit-scrollbar-thumb{
    background: #1f788c;
    border-radius: .5em;
}
*::selection{
    background: #175e6e;
    color: #ffffff;
}
.Navbar_navbar__3_ugw {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    background: white;
    border-bottom: 0.125em solid #175e6e;
    padding: 15px 35px;
    width: 100%;
    /*box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.1);*/
    box-sizing: border-box;
    z-index: 99;
    height: auto;
    
}
.Navbar_logo__1bsQS {
    width: 10em;
}

.Navbar_sessionbutton__1yJL5{
    background: #ffb103;
    border: none;
    color: BLACK;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 1.05em;
    position: relative;
    cursor: pointer;
    border-radius: 25px;
}
.Navbar_profile__2snP8 {
    width: 35px;
    height: 35px;
    border: 2px solid #e1e1e1;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.Navbar_session__fU6Pw{
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 10px;
    gap: 10px;
}
@media (max-width: 460px) {
    .Navbar_profile__2snP8, .Navbar_sessionbutton__1yJL5{
        display: none;
    }

}
.Sidebar_main__1djRm {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    background: #F3F3F1;
    transition: all ease .5s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 115px 35px 15px 35px;
    box-sizing: border-box;
}
.Sidebar_main__1djRm.Sidebar_hidden__1A56Q {
    transform: translateX(-100%);
}
.Sidebar_row__3fVR7{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.Sidebar_row__3fVR7 span{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    cursor: pointer;
}
.Sidebar_button__nav__2wFE3{
    background: #D9D9D9;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-weight: 700;
    font-size: 1.05em;
    cursor: pointer;
}
.Sidebar_toggle__1WhnI{
    background: #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.Sidebar_htoggle__z5zcn{
    display: none;
}
@media (max-width: 768px) {
    .Sidebar_main__1djRm {
        width: 100%;

        padding: 15px 35px;
    }
    .Sidebar_htoggle__z5zcn {
        display: flex;
    }
}
footer{
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    padding: 45px 35px 45px 35px;
    background: #175e6e;
    grid-gap: 20px;
    gap: 20px;
    z-index: 10;
}
footer p, footer a {
    color: white;
    text-align: left;
    line-height: 1.2;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    padding: 0;
    margin: 5px 0;
}
.Footer_textContainer__2gmTA{
    width: 50%;
}
.Footer_logo__SCo_Y {
    width: 350px;
    height: auto;
}

@media screen and (max-width: 720px) {
    footer {
        flex-direction: column;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
    }
    footer p{
        text-align: center;
    }
    .Footer_textContainer__2gmTA {
        width: 100%;
    }
    .Footer_logo__SCo_Y {
        width: 250px;
    }
}
.Confirmacion_container__2p7xZ {
    display: flex;
    height: 125px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Confirmacion_left__owz1c {
    margin-left: 35px;
}

.Confirmacion_right__23PSg {
    margin-right: 35px;
    text-align: left;
    padding-left: 15px;
}

.Confirmacion_transaction__18g-u {
    position: relative;
    margin: 50px 35px 50px 35px;
    text-align: left;
}

.Confirmacion_transactionCard__UYiiY {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    height: 171px;
    background-color: white;
    box-shadow: 0px -10px 0px 0px #1f788c,
                    0px 1px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.Confirmacion_print__RbLZH {
    position: absolute;
    color: #999999;
    text-align: right;
    top: -30px;
    right: 0px;
    bottom: 0;
    left: 0;
}

.Confirmacion_transactionDetails__3fzER {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
}
.Confirmacion_payerDetails__2iO9W {
    position: absolute;
    top: 65px;
    left: 10px;
}

.Confirmacion_payerDate__3pzgX {
    position: absolute;
    bottom: 25px;
    right: 10px
}

.Confirmacion_social__2PnOy {
    margin: 25px 0px 55px 0px;
}

.Confirmacion_social__2PnOy span {
    background: #ff5900;
    padding: 10px;
    color: white;
    border-radius: 50%;
    margin: 0px 10px 0px 10px;
    width: 35px;
    height: 35px;
}

.Confirmacion_social__2PnOy div {
    margin: 0px 35px 0px 35px;
    text-align: left;
    margin-bottom: 35px;
}

.Confirmacion_newsletter__2fS-J {
    margin-bottom: 50px;
}

.Confirmacion_newsletter__2fS-J input,
.Confirmacion_newsletter__2fS-J button{
    height: 35px;
    box-sizing: border-box;
    margin: 2px;
}

.Confirmacion_newsletter__2fS-J button {
    color: white;
    background-color: #ff5900;
    border: none;
    width: 55px;
    cursor: pointer;
    border-radius: 3px;
}

.Confirmacion_newsletter__2fS-J input {
    border: 1px solid #ddd;
   border-radius: 3px;
   padding: 5px;
}

.Confirmacion_banner__3cgGD {
    display: none;
}

@media(min-width: 1024px) {
    .Confirmacion_transaction__18g-u {
        width: 50%;
        margin-left: 35%;
    }

    .Confirmacion_right__23PSg {
        font-size: 1.55em;
        width: 500px;
    }

    .Confirmacion_social__2PnOy {
        position: absolute;
        left: 115px;
        top: 750px;
    }

    .Confirmacion_social__2PnOy div {
        width: 314px;
        text-align: center;
    }

    .Confirmacion_banner__3cgGD {
        display: block;
        background: #eee;
        width: 314px;
        height: 632px;
        position: absolute;
        top: 125px;
        left: 150px;
        background-image: url(/static/media/gracias_2.b2c4dc97.png);
    }

    .Confirmacion_newsletter__2fS-J {
        margin-bottom: 250px;
    }
}

@media(min-width: 1024px) and (max-width: 1678px) {
    .Confirmacion_transaction__18g-u, .Confirmacion_social__2PnOy div {
        width: 60%;
        position: relative;
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (-webkit-min-device-pixel-ratio: 2), screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .Confirmacion_banner__3cgGD {
        left: 65px;
    }

    .Confirmacion_social__2PnOy {
        left: -60px;
        text-align: center;
    }

    .Confirmacion_transactionCard__UYiiY {
        left: -375px;
    }

    .Confirmacion_print__RbLZH {
        top: -30px;
        right: 0px;
        left: 438px !important;
        text-align: left;
    }
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .Confirmacion_banner__3cgGD {
        left: 15px;
    }

    .Confirmacion_social__2PnOy {
        left: -90px;
        text-align: center;
    }

    .Confirmacion_transactionCard__UYiiY {
        left: -335px;
    }

    .Confirmacion_print__RbLZH {
        top: -30px;
        right: 0px;
        left: 343px;
        text-align: left;
    }
}


.Login_main__3rXP- {
    position: relative;
}
.Login_content__2znjw {
    background: white;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 358px);
    padding: 100px 0;
    align-items: center;
}
.Login_main_container__3Jjcx{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Login_login_container__3o6_r{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    margin-bottom: 50px;
}
.Login_login_container__3o6_r img{
    width: 250px;
    height: auto;
}
.Login_main_container__3Jjcx > form{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 650px;
    justify-content: center;
    padding: 45px 25px;
    grid-gap: 10px;
    gap: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 10px 0 0 10px;
    box-sizing: border-box;
}
.Login_form_group__2A33h{
    display: flex;
    flex-direction: column;
    position: relative;
}
.Login_container_recover__1wMwl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 40%;
}
.Login_form_row__3rzon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Login_form_group__2A33h .Login_label_form__DSa5L{
    font-weight: 700;
    font-size: 1.05rem;
    margin: 0;
    color: #175e6e;
    text-transform: capitalize;
}
.Login_form_group__2A33h input{
    padding: 0.4rem;
    border:none;
    border-bottom: 2px solid #e1e1e1;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.05rem;
    outline: none;
}
.Login_form_group__2A33h input:hover, .Login_form_group__2A33h input:focus{
    border-bottom: 2px solid #175e6e;
}
.Login_password_function__3R83w{
    color: #e1e1e1;
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    right: 5px;
}
.Login_form_group__2A33h span, .Login_form_row__3rzon span{
    font-weight: 600;
    font-size: 0.95rem;
    display: inline-block;
}
.Login_main_container__3Jjcx h1,p{
    text-align: center;
}
h1{
    font-weight: 700;
    font-size: 2.5rem;
    margin: 0;
}
p{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.05rem;
    margin: 0;
}
.Login_button_standar__24r__ {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    font-size: 1.05em;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.Login_button_standar__24r__:disabled{
    background-color: #d9d9d9;
    color: #a1a1a1;
}
.Login_button_register__M4NVr {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    background-color: #175e6e;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    font-size: 1.05em;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.Login_button_register__M4NVr:disabled{
    background-color: #d9d9d9;
    color: #a1a1a1;
}
.Login_button_submit__2rV4T {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    font-size: 1.05em;
    cursor: pointer;
    margin: 10px 0;
    text-decoration: none;
}

.Login_banner_login__1bYK5 img{
    height: 650px;
    width: 400px;
    object-fit: cover;
    border-radius: 0 10px 10px 0;
    margin-top: 4px;
}
@media screen and (max-width: 900px){
    .Login_container_recover__1wMwl{
        width: 80%;
    }
    .Login_main_container__3Jjcx{
        flex-direction: column;
    }
    .Login_banner_login__1bYK5{
        display: none;
    }
    .Login_main_container__3Jjcx > form{
        border-radius: 0 0 10px 10px;
        border: none;
        width: 70%;
        height: 100%;
    }
    .Login_login_container__3o6_r{
        margin-bottom: 0px;
    }
}
.Login_text__3F5QI {
    text-align: left;
    color: #000000;
    line-height: 1.5em;
    font-weight: 500;
    font-family: Roboto, sans-serif !important;
}
@media screen and (max-width: 575px){
    .Login_container_recover__1wMwl{
        width: 100%;
    }
    .Login_main_container__3Jjcx{
        flex-direction: column;
    }
    .Login_banner_login__1bYK5{
        display: none;
    }
    .Login_main_container__3Jjcx > form{
        border-radius: 0 0 10px 10px;
        border: none;
        width: 100%;
        height: 100%;
    }
    .Login_login_container__3o6_r{
        margin-bottom: 0px;
    }
}
.b-contain *, .b-contain *::before, .b-contain *::after {
	box-sizing: content-box !important;
}

.b-contain input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.b-contain span {
	line-height: 1.54;
	font-size: 1rem;
	font-family: inherit;
}

.b-contain {
	display: table;
	position: relative;
	padding-left: 1.8rem;
	cursor: pointer;
	margin-bottom: .5rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	background: rgba(241, 245, 248, 1);
	transition: background 250ms;
	border: 1px solid rgba(184, 194, 204, 1);
	border-radius: 0.125rem;
}

.b-contain input[type="radio"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 0.15rem;
	width: 0.15rem;
	background: rgba(241, 245, 248, 1);
	transition: background 250ms;
	border: 1px solid rgba(184, 194, 204, 1);
	border-radius: 2.0rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .45rem;
	top: .18rem;
	width: .25rem;
	height: .6rem;
	border: solid rgba(255, 255, 255, 1);
	border-width: 0 2px 2px 0;
	transition: background 250ms;
	transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .25rem;
	top: .25rem;
	width: 10px;
	height: 10px;
	border-radius: 2.0rem;
	background: rgba(255, 255, 255, 1);
	transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
	border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
	display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
	background: rgb(231, 238, 243);
}

.b-contain input:focus ~ .b-input {
	box-shadow: 0 0 0 2px rgba(255, 89, 0, 0.43);
}

.b-contain input:checked ~ .b-input {
	background: #175e6e;
	border-color: #175e6e;
}

.b-contain input[type="checkbox"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input::after {
	background: rgba(135, 149, 161, 1);
}

.b-contain input:checked:focus ~ .b-input, .b-contain:hover input:not([disabled]):checked ~ .b-input {
	background: #175e6e;
	border-color: #175e6e;
}

.b-contain .b-input::before {
	content: '';
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 3rem;
	height: 3rem;
	margin-left: -0.85rem;
	margin-top: -0.85rem;
	background: rgba(0, 130, 243, 1);
	border-radius: 2rem;
	opacity: .6;
	z-index: 99999;
	transform: scale(0);
}

@keyframes b-ripple {
	0% {
		transform: scale(0);
	}

	20% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

@keyframes b-ripple-duplicate {
	0% {
		transform: scale(0);
	}

	30% {
		transform: scale(1);
	}

	60% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

.b-contain input + .b-input::before {
	animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
	animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
	visibility: hidden;
}

.b-contain input:focus + .b-input::before {
	visibility: visible;
}

.b-contain:first-child .b-input::before {
	visibility: hidden;
}
.Perfil_main__eD0uc {
    position: relative;
}
.Perfil_d_none__3QTM0 {
    display: none;
}
.Perfil_content__3ukBe {
    background: white;
    display: flex;
    min-height: calc(100vh - 358px);
    padding: 100px 35px;
}
.Perfil_content__3ukBe .Perfil_welcome__1T--q {
    padding-top: 100px;
    text-align: left;
    width: 60%;
}
.Perfil_welcome__1T--q h1 {
    font-size: 2.5em;
    text-transform: capitalize;
    margin: 0;
}
.Perfil_welcome__1T--q h2{
    font-size: 2.5em;
    color: #175e6e;
    margin: 0;
}
.Perfil_welcome__1T--q span {
    color: black;
}
.Perfil_row__2R7XM {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    grid-gap: 50px;
    gap: 50px;
    width: 100%;
}
.Perfil_button_standar__3HGv5 {
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.05em;
}
.Perfil_button_submit__3Qt_v {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    font-size: 1.05em;
}
.Perfil_content_alert__3_6u-{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    margin: 20px 0;

}
.Perfil_alert__12_1B > *{
    min-width: 280px;
    font-family: "Roboto", sans-serif;
    text-align: left;
    background: #fef3c7;
    color: #92400e;
    padding: 15px 25px;
    border-radius: 10px;
}
.Perfil_alert__12_1B > p > span{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #92400e;
    cursor: pointer;
}
.Perfil_banner_content__17Y_R{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column;
}
.Perfil_banner_elements__aHPHL{
    background: #F3F3F1;
    padding: 20px 20px 80px 20px;
    border-radius: 10px;
    text-align: left;
}
.Perfil_facturacion__bg__3PFR9{
    background: url(/static/media/profile_billing.f0f89cf2.svg) no-repeat calc( 100% + 25px ) calc(0% + 25px) / 400px , #f3f3f1;
}
.Perfil_user_details__bg__2emWE{
    background: url(/static/media/profile_detail.5cef583b.svg) no-repeat calc( 100% + 25px ) calc(100% + 25px) / 400px , #f3f3f1;
}
.Perfil_payment_methond__bg__2bzjM{
    background: url(/static/media/profile_payment.ad3ceeda.svg) no-repeat calc( 100% + 25px ) calc(100% + 25px) / 400px , #f3f3f1;
}
.Perfil_banner_elements__aHPHL h3{
    color:#000;
    font-size: 1.5em;
    text-align: left;
    margin: 5px 0;
}
.Perfil_banner_elements__aHPHL p{
    color:#000;
    font-size: 1.2em;
    text-align: left;
    margin: 5px 0;
}
.Perfil_container__tc9FG{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.Perfil_metodos_content__2ZPvq{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.Perfil_profile_content__1FWmk{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: row;
    align-items: center;
}
.Perfil_profile_content__1FWmk img{
    width: 50px;
    border-radius: 100%;
    border: 2px solid #e1e1e1;
    object-fit: cover;
}
.Perfil_icon_payment__2kpd-{
    color: #175e6e;
    margin-right: 5px;
}
.Perfil_content_icon__2glCg{
    position: relative;
}
.Perfil_content_icon__2glCg .Perfil_icon_payment__2kpd-{
    position: absolute;
    bottom: .2em;
    left: .2em;
}
.Perfil_input_card__3eOqU{
    padding: 4px 0;
    padding-left: 1.5em;
}
.Perfil_fecha_expiracion__24wPy{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
}
.Perfil_details_content__1RccV{
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column;
    justify-content: start;
    text-align: left;
}
.Perfil_details_content__1RccV h3{
    margin: 0;
}
.Perfil_details_content__1RccV label{
    font-weight: 600;
    font-size: 1.25em;
    display: block;
}
.Perfil_details_content__1RccV input{
    padding-top: 5px;
    border:none;
    border-bottom: 2px solid #e1e1e1;
    font-size: 1.05em;
    font-family: Roboto, sans-serif;
    width: 100%;
    max-width: 350px;
    transition: 0.5s;
}
.Perfil_details_content__1RccV select{
    padding: 5px 0;
    border:none;
    border-bottom: 2px solid #e1e1e1;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    width: 100%;
    max-width: 350px;
    transition: 0.5s;
}
.Perfil_details_content__1RccV input:hover, .Perfil_details_content__1RccV input:focus{
    border-bottom: 2px solid #175e6e;
    outline: none;
}
.Perfil_submit_container__2qMIr{
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}
.Perfil_user_data__21i7M{font-family: Roboto, sans-serif;}
.Perfil_span__facturacion__3-bV1{
    font-family: Roboto, sans-serif;
}
table{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #e1e1e1;
    background: #f6f6f6;
    border-radius: 10px;
    padding: 10px;
}
.Perfil_table__row__1KQEb th, .Perfil_table__row__1KQEb td{
    padding: 5px 10px;
    font-family: Roboto, sans-serif;
    text-align: start;
    margin: 0;
}
.Perfil_table__fcolumn__1mUxe{
    display: flex;
    align-items: center;
    justify-content: start;
    grid-gap: 5px;
    gap: 5px;
}
.Perfil_icon__table__3jWhh{
    color: #fff;
    font-size: 15px;
    background: #175e6e;
    padding: 5px;
    border-radius: 100%;
}
@media screen and (max-width: 992px){
    .Perfil_content__3ukBe{
        flex-direction: column;
        grid-gap: 30px 0;
        gap: 30px 0;
    }
    .Perfil_content__3ukBe .Perfil_welcome__1T--q{width: 100%;}
}
@media screen and (max-width: 768px) {
    .Perfil_fecha_expiracion__24wPy div{
        width: 100%;
    }
}
@media screen and (max-width: 600px){

    .Perfil_details_content__1RccV{
        width: 100%;
    }
}
.Perfil_text__1hq8H {
    text-align: left;
    color: #000000;
    line-height: 1.5em;
    font-weight: 500;
    font-family: Roboto, sans-serif !important;
}

.Perfil_modal_container__3b4yK{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(40, 44, 52, 0.56);
    font-family: "Roboto", sans-serif;
}
.Perfil_modal_content__36G2F{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    background: #dc3545;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    text-align: center;
}
.Perfil_modal_tag__GIuy7{
    display: inline-block;
    background: #ffffff;
    color: #dc3545;
    padding: 5px 10px;
    border-radius: 15px;
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
}
.Perfil_button_alert__50vVh{
    background: #ffffff;
    color: #dc3545;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.05em;
}

.CreditCard_cmain__2mSwm {
    width: 300px;
    height: 175px;
    background: rgb(23,94,110);
    background: linear-gradient(152deg, rgba(23,94,110,1) 0%, rgba(31,120,140,1) 100%);
    border-radius: 10px;
    position: relative;
}

.CreditCard_cimain__2_JI3 {
    width: 300px;
    height: 175px;
    background-color: #e1e1e1;
    border-radius: 10px;
    position: relative;
}

.CreditCard_logo__zZG6L,
.CreditCard_chip__2loR_,
.CreditCard_number__1kNYQ {
    position: absolute;
    color: white;
}

.CreditCard_logo__zZG6L {
    right: 20px;
    top: 20px;
}

.CreditCard_chip__2loR_ {
    left: 20px;
    top: 75px;
}

.CreditCard_number__1kNYQ {
    left: 20px;
    bottom: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

.CreditCard_name__SDLMh {
    color: white;
    position: absolute;
    left: 20px;
    bottom: 40px;
    font-weight: 700;
    font-size: 20px;
}
.CreditCard_actions_card__1jKcV{
    position: absolute;
    right: 20px;
    bottom: 15px;
    display: flex;
    flex-direction: row;

    grid-gap: 5px;

    gap: 5px;
}
.CreditCard_activate__2xWYp,.CreditCard_delete__3qwj1{
    font-size: 0.8em;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    background: #b9b9b9;
    transition: .5s;
}
.CreditCard_activate__2xWYp:hover{
    background: #1f788c;
}
.CreditCard_delete__3qwj1:hover{
    background: #f43f5e;
}
.ImageCrop_main__gLk8_ {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.ImageCrop_contanier__jB8Nl{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}
.ImageCrop_contanier__jB8Nl input{
    font-weight: 600;
    text-transform: uppercase;
}
.ImageCrop_content_buttons__2SQEa{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}
input::file-selector-button{
    background: #175e6e;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-family: "Zilla Slab";
    font-weight: 600;
}
.ImageCrop_button_standar__13NfP {
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
}
.ImageCrop_button_standar__13NfP[disabled] {
    background-color: #d9d9d9;
    color: #a6a6a6;
    cursor: not-allowed;
}
.ImageCrop_button_submit__3t5Yg {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    text-transform: capitalize;
}
@media (max-width: 768px) {
    .ImageCrop_contanier__jB8Nl{
        display: flex;
        align-items: start;
        justify-content: start;
        grid-gap: 10px;
        gap: 10px;
        flex-direction: column;
    }
}
.Pager_main__3BgV2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 10px 0;
    font-family: Roboto, sans-serif;
}
.Pager_page__indicator__3K9oj{
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #175e6e;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}
.Pager_page__indicator__3K9oj:hover{
    background: #e1e1e1;
}
.Pager_page__indicator_disabled__3uT_Z{
    cursor: default;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #E1E1E1;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
}
.Legal_main__1PQ3Y {
    position: relative;
}
.Legal_content__3B8l- {
    background: white;
    display: flex;
    min-height: calc(100vh - 358px);
    padding: 100px 35px;
    flex-direction: column;
}
.Legal_content__3B8l- h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.Legal_content__3B8l- h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.Legal_content__3B8l- p, .Legal_content__3B8l- ul li, .Legal_content__3B8l- ol li, .Legal_content__3B8l- p a {
    font-size: 12pt;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: left;
    font-family: Roboto, sans-serif;
}
a{
    color: #000000;
}
/*
.main {
    min-height: 100vh;
    text-align: left;
    color: #333;
}

.main p,
.main h3 {
    text-align: left;
    line-height: 2;
    color: #333;
}

.main ol li,
.main ul li {
    color: #333;
    line-height: 2;
}*/

