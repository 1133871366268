.container {
    display: flex;
    height: 125px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.left {
    margin-left: 35px;
}

.right {
    margin-right: 35px;
    text-align: left;
    padding-left: 15px;
}

.transaction {
    position: relative;
    margin: 50px 35px 50px 35px;
    text-align: left;
}

.transactionCard {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    height: 171px;
    background-color: white;
    box-shadow: 0px -10px 0px 0px #1f788c,
                    0px 1px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.print {
    position: absolute;
    color: #999999;
    text-align: right;
    top: -30px;
    right: 0px;
    bottom: 0;
    left: 0;
}

.transactionDetails {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
}
.payerDetails {
    position: absolute;
    top: 65px;
    left: 10px;
}

.payerDate {
    position: absolute;
    bottom: 25px;
    right: 10px
}

.social {
    margin: 25px 0px 55px 0px;
}

.social span {
    background: #ff5900;
    padding: 10px;
    color: white;
    border-radius: 50%;
    margin: 0px 10px 0px 10px;
    width: 35px;
    height: 35px;
}

.social div {
    margin: 0px 35px 0px 35px;
    text-align: left;
    margin-bottom: 35px;
}

.newsletter {
    margin-bottom: 50px;
}

.newsletter input,
.newsletter button{
    height: 35px;
    box-sizing: border-box;
    margin: 2px;
}

.newsletter button {
    color: white;
    background-color: #ff5900;
    border: none;
    width: 55px;
    cursor: pointer;
    border-radius: 3px;
}

.newsletter input {
    border: 1px solid #ddd;
   border-radius: 3px;
   padding: 5px;
}

.banner {
    display: none;
}

@media(min-width: 1024px) {
    .transaction {
        width: 50%;
        margin-left: 35%;
    }

    .right {
        font-size: 1.55em;
        width: 500px;
    }

    .social {
        position: absolute;
        left: 115px;
        top: 750px;
    }

    .social div {
        width: 314px;
        text-align: center;
    }

    .banner {
        display: block;
        background: #eee;
        width: 314px;
        height: 632px;
        position: absolute;
        top: 125px;
        left: 150px;
        background-image: url(../../UI/Assets/gracias_2.png);
    }

    .newsletter {
        margin-bottom: 250px;
    }
}

@media(min-width: 1024px) and (max-width: 1678px) {
    .transaction, .social div {
        width: 60%;
        position: relative;
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .banner {
        left: 65px;
    }

    .social {
        left: -60px;
        text-align: center;
    }

    .transactionCard {
        left: -375px;
    }

    .print {
        top: -30px;
        right: 0px;
        left: 438px !important;
        text-align: left;
    }
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .banner {
        left: 15px;
    }

    .social {
        left: -90px;
        text-align: center;
    }

    .transactionCard {
        left: -335px;
    }

    .print {
        top: -30px;
        right: 0px;
        left: 343px;
        text-align: left;
    }
}

