@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
.main {
    position: relative;
}
.d_none {
    display: none;
}
.content {
    background: white;
    display: flex;
    min-height: calc(100vh - 358px);
    padding: 100px 35px;
}
.content .welcome {
    padding-top: 100px;
    text-align: left;
    width: 60%;
}
.welcome h1 {
    font-size: 2.5em;
    text-transform: capitalize;
    margin: 0;
}
.welcome h2{
    font-size: 2.5em;
    color: #175e6e;
    margin: 0;
}
.welcome span {
    color: black;
}
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 50px;
    width: 100%;
}
.button_standar {
    background-color: #ffb103;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    width: fit-content;
    font-size: 1.05em;
}
.button_submit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: fit-content;
    background-color: #d9d9d9;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    font-size: 1.05em;
}
.content_alert{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;

}
.alert > *{
    min-width: 280px;
    font-family: "Roboto", sans-serif;
    text-align: left;
    background: #fef3c7;
    color: #92400e;
    padding: 15px 25px;
    border-radius: 10px;
}
.alert > p > span{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #92400e;
    cursor: pointer;
}
.banner_content{
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.banner_elements{
    background: #F3F3F1;
    padding: 20px 20px 80px 20px;
    border-radius: 10px;
    text-align: left;
}
.facturacion__bg{
    background: url("../../UI/Assets/profile_billing.svg") no-repeat calc( 100% + 25px ) calc(0% + 25px) / 400px , #f3f3f1;
}
.user_details__bg{
    background: url("../../UI/Assets/profile_detail.svg") no-repeat calc( 100% + 25px ) calc(100% + 25px) / 400px , #f3f3f1;
}
.payment_methond__bg{
    background: url("../../UI/Assets/profile_payment.svg") no-repeat calc( 100% + 25px ) calc(100% + 25px) / 400px , #f3f3f1;
}
.banner_elements h3{
    color:#000;
    font-size: 1.5em;
    text-align: left;
    margin: 5px 0;
}
.banner_elements p{
    color:#000;
    font-size: 1.2em;
    text-align: left;
    margin: 5px 0;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.metodos_content{
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
.profile_content{
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
}
.profile_content img{
    width: 50px;
    border-radius: 100%;
    border: 2px solid #e1e1e1;
    object-fit: cover;
}
.icon_payment{
    color: #175e6e;
    margin-right: 5px;
}
.content_icon{
    position: relative;
}
.content_icon .icon_payment{
    position: absolute;
    bottom: .2em;
    left: .2em;
}
.input_card{
    padding: 4px 0;
    padding-left: 1.5em;
}
.fecha_expiracion{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}
.details_content{
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: start;
    text-align: left;
}
.details_content h3{
    margin: 0;
}
.details_content label{
    font-weight: 600;
    font-size: 1.25em;
    display: block;
}
.details_content input{
    padding-top: 5px;
    border:none;
    border-bottom: 2px solid #e1e1e1;
    font-size: 1.05em;
    font-family: Roboto, sans-serif;
    width: 100%;
    max-width: 350px;
    transition: 0.5s;
}
.details_content select{
    padding: 5px 0;
    border:none;
    border-bottom: 2px solid #e1e1e1;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    width: 100%;
    max-width: 350px;
    transition: 0.5s;
}
.details_content input:hover, .details_content input:focus{
    border-bottom: 2px solid #175e6e;
    outline: none;
}
.submit_container{
    display: flex;
    gap: 15px;
}
.user_data{font-family: Roboto, sans-serif;}
.span__facturacion{
    font-family: Roboto, sans-serif;
}
table{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #e1e1e1;
    background: #f6f6f6;
    border-radius: 10px;
    padding: 10px;
}
.table__row th, .table__row td{
    padding: 5px 10px;
    font-family: Roboto, sans-serif;
    text-align: start;
    margin: 0;
}
.table__fcolumn{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}
.icon__table{
    color: #fff;
    font-size: 15px;
    background: #175e6e;
    padding: 5px;
    border-radius: 100%;
}
@media screen and (max-width: 992px){
    .content{
        flex-direction: column;
        gap: 30px 0;
    }
    .content .welcome{width: 100%;}
}
@media screen and (max-width: 768px) {
    .fecha_expiracion div{
        width: 100%;
    }
}
@media screen and (max-width: 600px){

    .details_content{
        width: 100%;
    }
}
.text {
    text-align: left;
    color: #000000;
    line-height: 1.5em;
    font-weight: 500;
    font-family: Roboto, sans-serif !important;
}

.modal_container{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(40, 44, 52, 0.56);
    font-family: "Roboto", sans-serif;
}
.modal_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    background: #dc3545;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    text-align: center;
}
.modal_tag{
    display: inline-block;
    background: #ffffff;
    color: #dc3545;
    padding: 5px 10px;
    border-radius: 15px;
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
}
.button_alert{
    background: #ffffff;
    color: #dc3545;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    margin: 10px 0;
    width: fit-content;
    font-size: 1.05em;
}
