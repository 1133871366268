body::-webkit-scrollbar{
    width: .5em;
}
body::-webkit-scrollbar-thumb{
    background: #1f788c;
    border-radius: .5em;
}
*::selection{
    background: #175e6e;
    color: #ffffff;
}
.navbar {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    background: white;
    border-bottom: 0.125em solid #175e6e;
    padding: 15px 35px;
    width: 100%;
    /*box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.1);*/
    box-sizing: border-box;
    z-index: 99;
    height: auto;
    
}
.logo {
    width: 10em;
}

.sessionbutton{
    background: #ffb103;
    border: none;
    color: BLACK;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 1.05em;
    position: relative;
    cursor: pointer;
    border-radius: 25px;
}
.profile {
    width: 35px;
    height: 35px;
    border: 2px solid #e1e1e1;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.session{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}
@media (max-width: 460px) {
    .profile, .sessionbutton{
        display: none;
    }

}