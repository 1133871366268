.main {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 300px;
    background: #F3F3F1;
    transition: all ease .5s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 115px 35px 15px 35px;
    box-sizing: border-box;
}
.main.hidden {
    transform: translateX(-100%);
}
.row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.row span{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    cursor: pointer;
}
.button__nav{
    background: #D9D9D9;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-weight: 700;
    font-size: 1.05em;
    cursor: pointer;
}
.toggle{
    background: #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.htoggle{
    display: none;
}
@media (max-width: 768px) {
    .main {
        width: 100%;

        padding: 15px 35px;
    }
    .htoggle {
        display: flex;
    }
}